<template>
	<div id="app">
		
		<!-- 头部 -->
		<div class="kx_header" id="kx_header">
			<div class="kx_hdiv">
				<div class="kx_hlogo">
					<a href="https://www.scei.org.cn/"><img src="./assets/images/images/logo1.png" alt=""></a>
				</div>
				<div class="kx_hsearch">
					<div class="hx_hinput">
						<input class="hinput-keyword" type="text" v-model="keyWordVal" placeholder="请输入关键词">
						<div class="search-icon">
							<a :href="'https://scei.org.cn/search.html?keyword=' + keyWordVal">
								<img src="./assets/images/images/search_btn.png" alt="">
							</a>
						</div>
					</div>
					<!-- <ul>
						<li class="share_item">
							<img src="./assets/images/images/weibo.png" alt="">
						</li>
						<li class="share_item">
							<img src="./assets/images/images/weixin.png" alt="">
						</li>
						<li class="share_item">
							<img src="./assets/images/images/douyin.png" alt="">
						</li>
					</ul> -->
					
				</div>
			</div>
		</div>
		<!-- 导航栏 -->
		<div class="kx_nav">
			<ul class="menu_list clearfix">
				<li class="list-navitem">
					<a class="navitem-firsta" href="https://www.scei.org.cn/">首页</a>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="#">组织机构</a>
					<dl class="subNav clearfix" style="width: 300%;">
						<dd><a href="https://scei.org.cn/center.html" target="_blank">中心简介</a></dd>
						<dd><a href="https://scei.org.cn/wzzzjj.html" target="_blank">大事记</a></dd>
					</dl>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="#">工作动态</a>
					<dl class="subNav clearfix" style="width: 300%;">
						<dd><a href="https://scei.org.cn/zxdt/list_9_1.html" target="_blank">中心动态</a></dd>
						<dd><a href="http://qykx.scei.org.cn/portal/zkx/6/businessCenter/businessNewsList.action?type=dfdt" target="_blank">地方动态</a></dd>
						<dd><a href="http://qykx.scei.org.cn/portal/zkx/6/businessCenter/businessNewsList.action?type=qykxdt" target="_blank">企业动态</a></dd>
					</dl>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="https://scei.org.cn/tzgg/gglist_23_1.html" target="_blank">通知通告</a>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="#">党建工作</a>
					<dl class="subNav clearfix" style="width: 300%;">
						<dd><a href="https://scei.org.cn/liuzhongquanhui.html" target="_blank">十九届六中全会</a></dd>
						<dd><a href="https://scei.org.cn/djdt/djlist_53_1.html" target="_blank">党建动态</a></dd>
						<dd><a href="https://scei.org.cn/xxyd/djlist_28_1.html" target="_blank">学习园地</a></dd>
						<dd><a href="https://scei.org.cn/dsxxjy/djlist_29_1.html" target="_blank">党史学习教育</a></dd>
						<dd><a href="https://www.scei.org.cn/images/zhuanti/dqxdfh/index.html" target="_blank">党旗下的东方红</a></dd>
					</dl>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="https://scei.org.cn/pinpai.html">重点活动</a>
					<dl class="subNav clearfix" style="width: 300%;">
						<dd><a href="http://cxffds.scei.org.cn/" target="_blank" title="中国创新方法大赛">中国创新方法大赛</a></dd>
						<dd><a href="https://scei.org.cn/images/zhuanti/wstdf3/index.html" target="_blank" title="世界科技与发展论坛">世界科技与发展论坛</a></dd>
						<dd><a href="https://www.chinaief.net/" target="_blank" title="中国创新创业成果交易会">中国创新创业成果交易会</a></dd>
						<dd><a href="https://www.kczg.org.cn/Cloudclassroom" target="_blank" title="中小企业创新发展云课堂">中小企业创新发展云课堂</a></dd>
						<dd><a href="https://scei.org.cn/images/zhuanti/ip2021/index.html" target="_blank" title="中国科协全国知识产权宣传周活动">中国科协全国知识产权宣传周活动</a></dd>
						<dd><a href="https://scei.org.cn/touzidahui.html" target="_blank" title="“科创中国”创新创业投资大会">“科创中国”创新创业投资大会</a></dd>
						<dd><a href="https://scei.org.cn/dajiatan.html" target="_blank" title="“科创中国”企业创新大家谈">“科创中国”企业创新大家谈</a></dd>
						<dd><a href="https://www.qiyekexie.com/portal/zkx/6/cxdr2021/index.html" target="_blank" title="“科创中国”企业“创新达人”宣讲">“科创中国”企业“创新达人”宣讲</a></dd>
						<dd><a href="https://scei.org.cn/yunluyan.html" target="_blank" title="“科创中国”创新创业项目云路演">“科创中国”创新创业项目云路演</a></dd>
						<dd><a href="https://scei.org.cn/images/zhuanti/kxnh23/index.html" target="_blank" title="“科创中国”科技创新企业家高峰论坛">“科创中国”科技创新企业家高峰论坛</a></dd>
						<dd><a href="https://kczg.zgclmlhh.org.cn/r/index" target="_blank" title="“科创中国”中关村·科技创新创业大赛">“科创中国”中关村·科技创新创业大赛</a></dd>
					</dl>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="https://scei.org.cn/qiye.html" target="_blank">数字化服务</a>
					<dl class="subNav clearfix" style="width: 300%;">
						<dd><a href="https://report.wanxiangyun.net/scei/reports" target="_blank" title="专利分析报告自动生成系统">专利分析报告自动生成系统</a></dd>
						<dd><a href="https://www.patentstar.com.cn/" target="_blank" title="专利之星检索系统">专利之星检索系统</a></dd>
						<dd><a href="https://patent.scei.org.cn/login.do" target="_blank" title="海外专利信息资源库">海外专利信息资源系统</a></dd>
						<dd><a href="http://patdata2.cnipa.gov.cn/" target="_blank" title="专利数据服务试验系统">专利数据服务试验系统</a></dd>
						<dd><a href="https://www.qiyekexie.com/portal/subject/digitalMap/index.action" target="_blank" title="中国科技创业数字地图">中国科技创业数字地图</a></dd>
						<dd><a href="https://zcxt.scei.org.cn/" target="_blank" title="企业技术创新力自测系统">企业技术创新力自测系统</a></dd>
					</dl>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="http://qykx.scei.org.cn/portal/zkx/6/businessCenter/index.action" target="_blank">企业科协</a>
				</li>
				<li class="list-navitem">
					<a class="navitem-firsta" href="https://patent.scei.org.cn/patent-report/research/index/" target="_blank">智库研报</a>
				</li>
			</ul>
		</div>
		

		<router-view/>
		<!-- 页尾 -->
		<div class="kx_footer" id="kx_footer">
			<div class="kx_div">
				<div>
					<img src="./assets/images/images/logo.png" alt="">
					<img class="wanxianglogo" src="./assets/images/wanxiangyunlogo.png" alt="">
				</div>
				<div class="kx_href">
					<div class="kx_copyright">
						<p>通讯地址：北京市朝阳区白家庄东里13号楼 电子邮箱：scei@cast.org.cn</p>
						<p>京公网安备 11010502042448号 <a class="kx_copyright-a" href="https://beian.miit.gov.cn/" target="_blank">京ICP备16034292号-3</a></p>
						<p>版权所有：中国科协企业创新服务中心 网站声明</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 返回顶部 -->
		<template>
			<el-backtop :right="right" :bottom="bottom">
				<div class="gotops">
					<img src="./assets/images/gotop.png" alt="">
				</div>
			</el-backtop>
		</template>
	</div>
</template>
<script>
	export default {
		data(){
			return{
				keyWordVal: '',
				activeIndex: '1',
				// 回到顶部按钮位置
				right: 20,
				bottom: 40
			}
		},
		methods:{
			
		}
	}
</script>
<style>
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		width: 100%;
		min-width: 1200px;
		overflow-x: hidden;
		min-height: 100%;
		position: relative;
		/* font-family: Avenir, Helvetica, Arial, sans-serif; */
		/* font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif; */
		background: rgb(245, 245, 245);
		font-family: 'Microsoft YaHei',Arial,Helvetica,sans-serif;
	}
	
	h1, h2, h3, h4, h5, h6, p, figure, form, blockquote {
		margin: 0;
	}
	h1, h2, h3, h4, h5, h6 {
		padding: 0;
		margin: .05rem 0;
		color: #1A192B;
	}
	*, *::before, *::after{
		box-sizing: border-box;
	}
	a{
		background: transparent;
		text-decoration: none;
		-webkit-tap-highlight-color: rgba(255,0,0,0);
	}
	a{
		outline: none;
	}
	a{
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
	a{
		background-color: transparent;
		-webkit-text-decoration-skip: objects;
	}
	a{
		color: #5659e0;
	}
	img {
		border-style: none;
	}
	img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	button, input, optgroup, select, textarea{
		font-family: sans-serif;
		font-size: 100%;
		line-height: 1.15;
		margin: 0;
	}
	button, input{
		overflow: visible;
	}

	/* 新的页头css */
	.kx_header{padding: 20px 0;width: 100%;}
	.kx_hdiv{margin: 0 auto;width: 1200px;display: flex;justify-content: space-between;align-items: center;}
	.kx_hdiv>div{height: 100%;}
	.kx_hlogo img{height: 56px;}
	.kx_hsearch{display: flex;align-items: center;}
	.kx_hsearch>ul{display: flex;}
	.share_item{margin-right: 20px;width: 38px;height: 38px;background: rgba(141,141,141,0.11);border-radius: 50%;text-align: center;display: flex;justify-content: center;align-items: center;}
	.share_item>img{width: 24px;height: 24px;}
	.hx_hinput{/* margin-right: 20px; */width: 246px;height: 37px;background: rgba(141, 141, 141, 0.11);border-radius: 19px;overflow: hidden;}
	.hx_hinput>input{float: left;padding: 0 10px;width: 216px;height: 100%;border: none;background: none;box-sizing: border-box;outline: none;font-size: 14px;}
	.search-icon {float: left;width: 30px;height: 37px;display: flex;align-items: center;cursor: pointer;}
	/* 导航栏部分 */
	.kx_nav{height: 56px;background: url('./assets/images/images/WechatIMG889.png') no-repeat 100%;}
	.menu_list{margin: 0 auto;width: 1200px;height: 100%;}
	.menu_list a{color: #fff;font-size: 16px;}
	.kx_nav .list-navitem{position: relative;float: left;/* margin: 0 26px; */margin-right: 20px;padding: 0 16px;height: 56px;}
	.kx_nav .list-navitem .navitem-firsta{display: block;line-height: 56px;}
	.kx_nav dl{display: none;position: absolute;left: 0;width: 100%;z-index: 999;opacity: .9;}
	.kx_nav dl dd{height: 30px;line-height: 30px;background-color: #335e8c;text-align: left;}
	.kx_nav dl dd a{display: block;width: 100%;height: 100%;color: #fff;font-size: 14px;text-indent: 16px;}
	.kx_nav dl dd:hover{background-color: #6f97c1;}
	.kx_nav ul li:hover dl{display: block;}
	
	
	/* 页尾 */
	.kx_footer{background:#1d59b2;color: #fff;}
	.kx_div{margin: 0 auto;width: 1200px;display: flex;justify-content: space-between;align-items: center;}
	.wanxianglogo{margin-top: 10px;margin-right: 0px;width: 415px;}
	.kx_href{padding: 40px;box-sizing: border-box;}
	.kx_copyright{line-height: 2;text-align: center;font-size: 16px;}
	.kx_copyright .kx_copyright-a{color: #fff;}
	/* 返回顶部按钮 */
	.el-backtop{width: 50px;height: 50px;}
	.gotops{height: 100%;width: 100%;color:#ffffff;display:flex;align-items:center;justify-content: center;background-color:#1d59b2;border-radius:50%;border:none;}
	.gotops img{width: 25px;height: 25px;}
	.gotops:hover{background-color: #e46856;}
</style>
